import axios from "axios";
import axiosRetry from "axios-retry";
import refreshTokenAxiosInstance from "./refreshAxiosInstance";
import { isSolisClientError } from "../utils/utils";
import { defaultPageSize } from "../utils/constants";

/**
 * A custom instance of axios without the fancy interceptors.
 * Used for downloading files via presigned URLs.
 */
export const axiosDownload = axios.create({
  baseURL: process.env.REACT_APP_SOLIS_API_BASE_URL,
});

/**
 * This can be used to cancel all future axios requests.
 * It is currently used during session timeouts to prevent
 * out of control behavior.
 */
export const abortController = new AbortController();

/**
 * Use axiosRetry with Exponential back-off retry delay between requests
 * Defaults to 3 retries on network errors and 5xx errors on idempotent requests
 * (GET, HEAD, OPTIONS, PUT or DELETE)
 */
axiosRetry(axios, { retryDelay: axiosRetry.exponentialDelay });

/**
 * Adds axios interceptor that adds X-User-Agent header to requests.
 * Does not add to requests where withCredentials is true.
 */
axios.interceptors.request.use((config) => {
  if (!config.withCredentials) {
    config.headers["X-User-Agent"] = `SolisUI/${process.env.REACT_APP_VERSION}`;
  }
  return config;
});

/**
 * Generic method to start requests to the API.
 *
 * @param {string} url The URL to request
 * @param {string} method The Method to request
 * @param {string} token The JWT token to send.
 * @param {*} params Any custom parameters to send.
 * @param {*} payload The payload/request body to send.
 * @param {*} customHeaders Any custom headers to send.
 * @returns An axios request in the form of a Promise.
 */
const apiRequest = (
  url,
  method,
  token,
  params = null,
  payload = null,
  customHeaders = null
) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...customHeaders,
  };
  return axios.request({
    url: url,
    method: method,
    headers: headers,
    signal: abortController.signal,
    params: params || {},
    data: payload || {},
  });
};

/**
 * Builds and logs an error message based upon an API Axios error.
 * Called when executing the use API error hook.
 *
 * @param {*} error The raw API Axios error.
 * @return The built error message.
 */
export const logApiError = (error) => {
  let errMsg = "";

  if (error.message) {
    errMsg += "Error Message: " + error.message + "\n";
  }

  if (error.response && error.response.data) {
    if (error.response.data.message) {
      errMsg += "API Error Message: " + error.response.data.message + "\n";
    }
    if (error.response.data.errorType) {
      errMsg += "API Error Type: " + error.response.data.errorType;
    }
  }

  console.group("API Error");
  console.error(errMsg);
  console.debug(error);
  console.groupEnd();

  return errMsg;
};

/**
 * Fetches applications from the API.
 *
 * @param {string} token The JWT token to send.
 * @param {number} pageSize The size of the page to request.
 * @param {number} page The page number.
 * @param {*} filter A filter to apply to the request.
 * @param {string} sortBy A field of the app documents to sort by.
 * @returns The response of the axios request.
 */
export const FetchApplications = (
  token,
  pageSize = 25,
  page = 1,
  filter = null,
  sortBy = null
) => {
  let queryParams = { pageSize: pageSize, page: page };
  if (filter) {
    queryParams = {
      ...queryParams,
      filter: encodeURIComponent(JSON.stringify(filter)),
    };
  }
  if (sortBy) {
    queryParams = {
      ...queryParams,
      sortBy: sortBy,
    };
  }
  return apiRequest("/apps", "get", token, queryParams, null, null).then(
    (resp) => {
      return resp.data;
    }
  );
};

/**
 * Fetches applications from the API based on the user's id.
 *
 * @param {string} token The JWT token to send.
 * @param {*} pageSize The size of the page to request.
 * @param {*} page The page number.
 * @param {*} cec_id The user's CECID.
 * @returns The response of the axios request.
 */
export const FetchUserApplications = (token, pageSize, page, cecId) => {
  return apiRequest(
    "/apps",
    "get",
    token,
    { pageSize: pageSize, page: page, cec_id: cecId },
    null,
    null
  ).then((resp) => {
    const apps = resp.data.apps;
    return apps;
  });
};

/**
 * Gets the integer count of apps available to the user.
 *
 * @param {*} token The JWT token.
 * @param {boolean} estimate Whether to estimate the count or not.
 * @returns An integer count of apps.
 */
export const GetAppCount = (token, estimate = true) => {
  return apiRequest(
    "/apps/count",
    "get",
    token,
    { estimate: estimate },
    null,
    null
  ).then((resp) => {
    const count = resp.data.count;
    return count;
  });
};

/**
 * Sends a PATCH request to edit an application.
 *
 * @param {string} token JWT used for authentication.
 * @param {string} id The ID of the application being edited.
 * @returns null
 */
export const EditApplication = (token, id, payload) => {
  return apiRequest(`/apps/${id}`, "patch", token, null, payload, null);
};

/**
 * Request to delete an application with the given ID.
 *
 * @param {string} token JWT used for authentication.
 * @param {string} id The ID of the application being edited.
 * @returns A promise for the sent request.
 */
export const DeleteApplication = (token, id) => {
  return apiRequest(`/apps/${id}`, "delete", token, null, null).then(() => {
    return;
  });
};

/**
 * Fetches targets from the API.
 *
 * @param {string} token The JWT token to send.
 * @param {number} pageSize The size of the page to request.
 * @param {number} page The page number.
 * @param {*} filter A JSON filter for the target documents.
 * @param {boolean} includeEmbedded A boolean to indicate whether to include embedded documents.
 * @returns The response of the axios request.
 */
export const FetchTargets = (
  token,
  pageSize = 25,
  page = 1,
  filter = null,
  includeEmbedded = false
) => {
  let queryParams = {
    pageSize: pageSize,
    page: page,
    includeEmbedded: includeEmbedded,
  };
  if (filter) {
    queryParams = {
      ...queryParams,
      filter: encodeURIComponent(JSON.stringify(filter)),
    };
  }
  return apiRequest("/targets", "get", token, queryParams, null, null).then(
    (resp) => {
      return resp.data;
    }
  );
};

/**
 * Gets the integer count of targets available to the user.
 *
 * @param {*} token The JWT token.
 * @param {boolean} estimate Whether to estimate the count or not.
 * @returns An integer count of targets.
 */
export const GetTargetCount = (token, estimate = true) => {
  return apiRequest(
    "/targets/count",
    "get",
    token,
    { estimate: estimate },
    null,
    null
  ).then((resp) => {
    const count = resp.data.count;
    return count;
  });
};

/**
 * Gets the integer count of users available to the user.
 *
 * @param {*} token The JWT token.
 * @param {boolean} estimate Whether to estimate the count or not.
 * @returns An integer count of users.
 */
export const GetUserCount = (token, estimate = true) => {
  return apiRequest(
    "/users/count",
    "get",
    token,
    { estimate: estimate },
    null,
    null
  ).then((resp) => {
    const count = resp.data.count;
    return count;
  });
};

/**
 * Gets the user's profile.
 *
 * @param {*} token The JWT token.
 * @param {*} cec_id The user's login.
 * @returns The user profile object.
 */
export const GetUserProfile = (token) => {
  return apiRequest("/users/me", "get", token, null, null, null).then(
    (resp) => {
      const user = resp.data;
      return user;
    }
  );
};

/**
 * Fetches targets corresponding to an app.
 *
 * @param {string} token The JWT token to send.
 * @param {string} appId The appID.
 * @returns The response of the axios request.
 */
export const FetchTargetsForApp = (token, appId) => {
  return apiRequest(
    `/apps/${appId}/targets`,
    "get",
    token,
    null,
    null,
    null
  ).then((resp) => {
    const targets = resp.data;
    return targets;
  });
};

/**
 * Fetches a single app.
 *
 * @param {string} token The JWT token to send.
 * @param {string} appId The appID.
 * @returns The response of the axios request.
 */
export const FetchSingleApp = (token, appId) => {
  return apiRequest(`/apps/${appId}`, "get", token, null, null, null).then(
    (resp) => {
      const app = resp.data;
      return app;
    }
  );
};

/**
 * Fetches all scan configs.
 *
 * @param {*} token The request token.
 * @param {number} pageSize The size of the page of results to return.
 * @param {number} page The page number to return.
 * @param {*} filter A filter object to narrow down the list.
 * @param {boolean} includeEmbedded A boolean to indicate whether to include embedded documents.
 * @returns The response from the request.
 */
export const FetchScanConfigs = async (
  token,
  pageSize = 10,
  page = 1,
  filter = null,
  includeEmbedded = false
) => {
  let queryParams = {
    pageSize: pageSize,
    page: page,
    includeEmbedded: includeEmbedded,
  };
  if (filter) {
    queryParams = {
      ...queryParams,
      filter: encodeURIComponent(JSON.stringify(filter)),
    };
  }
  const response = await apiRequest(
    "/scan-configs",
    "get",
    token,
    queryParams,
    null,
    null
  );
  return response.data;
};

/**
 * Fetches a single scan config by Id.
 *
 * @param {string} token The request token.
 * @param {string} scanConfigId The ID of the scan config to fetch.
 * @param {boolean} includeEmbedded A boolean to indicate whether to include embedded documents.
 * @returns The response from the request.
 */
export const FetchScanConfig = (
  token,
  scanConfigId,
  includeEmbedded = false
) => {
  let queryParams = {
    includeEmbedded: includeEmbedded,
  };
  return apiRequest(
    `/scan-configs/${scanConfigId}`,
    "get",
    token,
    queryParams,
    null,
    null
  ).then((resp) => {
    return resp.data;
  });
};

/**
 * Deletes a scan config.
 *
 * @param {string} token The request token.
 * @param {string} scanConfigId The ID of the scan config to delete.
 * @returns The response from the request.
 */
export const DeleteScanConfig = (token, scanConfigId) => {
  return apiRequest(
    `/scan-configs/${scanConfigId}`,
    "delete",
    token,
    null,
    null,
    null
  ).then((resp) => {
    return resp;
  });
};

/**
 * API request to edit a scan configuration.
 *
 * @param {string} token The JWT used in authentication.
 * @param {string} scanConfigId The ID of the scan configuration to edit.
 * @param {string} name The name for the scan configuration.
 * @param {*} config The actual configuration object.
 * @param {string} scope The scope for access to this configuration.
 * @param {*} revision The revision document from the scan config.
 * @param {*} tags Tags on the configuration.
 * @param {string} chariotId The ID of the chariot to run this configuration with.
 * @returns A promise for the request resolution.
 */
export const PatchScanConfig = (
  token,
  scanConfigId,
  name,
  config,
  scope,
  revision,
  tags = {},
  chariotId = null
) => {
  let payload = {
    name: name,
    config: config,
    scope: [{ app_id: scope, role: "admin" }],
    tags: tags,
    chariot_id: chariotId,
    revision: revision,
  };
  return apiRequest(
    `/scan-configs/${scanConfigId}`,
    "patch",
    token,
    null,
    payload,
    null
  ).then((resp) => {
    return resp;
  });
};

/**
 * Adds a scan configuration to an app via application ID.
 *
 * @param {string} token The JWT used in authentication.
 * @param {string} name The name for the scan configuration.
 * @param {*} config The actual configuration object.
 * @param {string} scope The scope for access to this configuration.
 * @param {*} tags Tags on the configuration.
 * @param {string} chariotId The ID of the chariot to run this configuration with.
 * @returns A promise for the request resolution.
 */
export const PostScanConfig = (
  token,
  name,
  config,
  scope,
  tags = {},
  chariotId = null
) => {
  let payload = {
    name: name,
    config: config,
    scope: [{ app_id: scope, role: "admin" }],
    tags: tags,
  };
  if (chariotId) {
    payload = { ...payload, chariot_id: chariotId };
  }
  return apiRequest(`/scan-configs`, "post", token, null, payload, null);
};

/**
 * Adds a scan configuration to an app via application ID.
 *
 * @param {string} token The JWT token to send.
 * @param {string} name The name for the scan configuration.
 * @param {*} config The configuration object.
 * @param {*} scope The scope for this scan configuration.
 * @param {Array} tags Any tags to add to the scan configuration.
 * @param {string} chariotId An optional Chariot Id to add to the configuration.
 * @returns The axios request.
 */
export const CopyScanConfig = (
  token,
  name,
  config,
  scope,
  tags = {},
  chariotId = null
) => {
  let payload = {
    name: name,
    config: config,
    scope: scope,
    tags: tags,
  };
  if (chariotId) {
    payload = { ...payload, chariot_id: chariotId };
  }
  return apiRequest(`/scan-configs`, "post", token, null, payload, null);
};

/**
 * Adds an app to the database.
 *
 * @param {string} token The JWT token to send.
 * @param {*} data The body of the app to add.
 * @returns The response of the axios request.
 */
export const AddApp = (token, data) => {
  return apiRequest("/apps", "post", token, null, data, null).then((resp) => {
    return resp;
  });
};

/**
 * Starts a scan via post request.
 *
 * @param {string} token The JWT token to send.
 * @param {string} appId The appID.
 * @param {string} targetId The scan configuration ID.
 * @param {*} data The body of the scan start request.
 * @returns The axios request.
 */
export const StartScan = (token, appId, targetId, data) => {
  return apiRequest(
    `/apps/${appId}/targets/${targetId}/scans`,
    "post",
    token,
    null,
    data,
    null
  );
};

/**
 * API request to terminate a scan.
 *
 * @param {string} token The JWT used for authorization
 * @param {*} scan The scan document that we are going to terminate.
 * @returns The axios request.
 */
export const TerminateScan = (token, scan) => {
  return apiRequest(`/scans/${scan._id}`, "delete", token, null, null, null);
};

/**
 * Fetches total count of targets available.
 *
 * @param {string} token The JWT token to send.
 * @param {boolean} estimate Whether to estimate the count or not.
 * @returns The response: total count of targets available.
 */
export const FetchTargetCount = (token, estimate = true) => {
  return axios
    .request({
      url: "/targets/count",
      params: { estimate: estimate },
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp) => {
      const count = resp.data;
      return count;
    });
};

/**
 * Fetches all targets associated with a specific target.
 *
 * @param {string} token The JWT token to send.
 * @param {string} id The selected application's id.
 * @returns The response: all targets available.
 */

export const FetchApplicationTargets = (token, id) => {
  return axios
    .request({
      url: `/apps/${id}/targets`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp) => {
      const appTargets = resp.data.targets;
      return appTargets;
    });
};

/**
 * Fetches a single target from ID.
 *
 * @param {string} token The JWT for authentication
 * @param {string} id The target Id.
 * @returns The target document
 */
export const FetchSingleTarget = (token, id, includeEmbedded = false) => {
  let queryParams = {
    includeEmbedded: includeEmbedded,
  };
  return apiRequest(
    `/targets/${id}`,
    "get",
    token,
    queryParams,
    null,
    null
  ).then((resp) => {
    return resp.data;
  });
};

/**
 * Delete's a specific target from the associated target list within the Target Controller.
 *
 * @param {string} token The JWT token to send.
 * @param {string} id The selected target's id.
 * @returns The response: api success or error message.
 */
export const DeleteTarget = (token, id) => {
  return axios
    .request({
      url: `/targets/${id}`,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp) => {
      return resp;
    });
};

/**
 * Edits's a specific target's details within the Target Controller.
 *
 * @param {string} token The JWT token to send.
 * @param {string} id The selected target's id.
 * @returns The response: api success or error message.
 */
export const EditTarget = (token, id, payload) => {
  return apiRequest(`/targets/${id}`, "patch", token, null, payload, null);
};

/**
 * Fetches all scans for an appID and targetId.
 *
 * @param {string} token The JWT.
 * @param {string} appId The app ID.
 * @param {string} targetId The target ID.
 * @param {number} pageSize The integer page size.
 * @param {number} page The page number we are on.
 * @returns All the scans for a given app and target.
 */
export const FetchScans = (token, appId, targetId, pageSize = 10, page = 1) => {
  return apiRequest(
    `/apps/${appId}/targets/${targetId}/scans`,
    "get",
    token,
    { pageSize: pageSize, page: page },
    null,
    null
  ).then((resp) => {
    const scans = resp.data;
    return scans;
  });
};

/**
 * Fetches all scans from the /scans endpoint.
 *
 * @param {string} token JWT used for authorization.
 * @param {number} pageSize The number of scans to return.
 * @param {number} page The page number to return.
 * @param {string} filter An optional filter to apply to the results.
 * @param {string} sortBy What field to sort the scans by.
 * @param {boolean} includeEmbedded Whether to include embedded documents or not.
 * @returns The response body from the request.
 */
export const FetchAllScans = async (
  token,
  page = 1,
  pageSize = 10,
  filter = null,
  sortBy = null,
  includeEmbedded = false
) => {
  let queryParams = {
    page: page,
    pageSize: pageSize,
    includeEmbedded: includeEmbedded,
  };
  if (sortBy) {
    queryParams = { ...queryParams, sortBy: sortBy };
  }
  if (filter) {
    queryParams = { ...queryParams, filter: filter };
  }
  const resp = await apiRequest("/scans", "GET", token, queryParams);
  return resp.data;
};

/**
 * Fetches counts for all the scans and an optional breakdown by status.
 *
 * @param {string} token The JWT authorization token
 * @param {boolean} breakdown A flag to indicate that a breakdown of statuses should be returned.
 * @param {boolean} estimate Whether to estimate on the total count.
 * @returns The request to the API.
 */
export const FetchScanCounts = async (
  token,
  breakdown = false,
  estimate = true
) => {
  return await apiRequest("/scans/count", "get", token, {
    breakdown: breakdown,
    estimate: estimate,
  });
};

/**
 * Creates a scan schedule for the given app and target.
 *
 * @param {string} token A JWT authorization token.
 * @param {string} appId The object Id for the app that this schedule is being created for.
 * @param {string} targetId The object Id for the target that this schedule is for.
 * @param {*} payload The JSON body that defines the schedule.
 * @returns The data from the scan schedule creation response.
 */
export const CreateScanSchedule = async (token, appId, targetId, payload) => {
  const resp = await apiRequest(
    `/apps/${appId}/targets/${targetId}/scan-schedules`,
    "POST",
    token,
    null,
    payload
  );
  return resp.data;
};

/**
 * Deletes a scan schedule.
 *
 * @param {string} token A JWT authorization token.
 * @param {string} appId The object Id for the app that this schedule was created with.
 * @param {string} targetId The object Id for the target that this schedule was created with.
 * @param {string} scheduleId The schedule object Id to delete.
 * @returns The response object from the request.
 */
export const DeleteScanSchedule = async (
  token,
  appId,
  targetId,
  scheduleId
) => {
  const resp = await apiRequest(
    `/apps/${appId}/targets/${targetId}/scan-schedules/${scheduleId}`,
    "DELETE",
    token
  );
  return resp;
};

/**
 * Edits the scan schedule.
 *
 * @param {string} token A JWT authorization token.
 * @param {string} appId The object Id of the app.
 * @param {string} targetId The object Id of the target.
 * @param {string} scheduleId The object Id of the schedule being edited.
 * @param {*} payload The edits to submit to the schedule.
 * @returns The response from the request.
 */
export const PatchScanSchedule = async (
  token,
  appId,
  targetId,
  scheduleId,
  payload
) => {
  const resp = await apiRequest(
    `/apps/${appId}/targets/${targetId}/scan-schedules/${scheduleId}`,
    "PATCH",
    token,
    null,
    payload
  );
  return resp;
};

/**
 * Fetches a single scan from scan ID.
 *
 * @param {*} token The JWT.
 * @param {*} scanId The ID of the scan.
 * @param {*} includeEmbedded docs of the scan.
 * @returns The scan document.
 */
export const FetchSingleScan = (token, scanId, includeEmbedded = false) => {
  let queryParams = {
    includeEmbedded: includeEmbedded,
  };
  return apiRequest(`/scans/${scanId}`, "get", token, queryParams).then(
    (resp) => {
      const scan = resp.data;
      return scan;
    }
  );
};

/**
 * Fetches a job for a scan.
 *
 * @param {*} token The JWT.
 * @param {*} scanId The ID of the scan.
 * @returns The job document.
 */
export const FetchJobInfo = (token, scanId) => {
  return apiRequest(
    `/scans/${scanId}/job-details`,
    "get",
    token,
    null,
    null,
    null
  ).then((resp) => {
    const job = resp.data;
    return job;
  });
};

/**
 * Gets the link to an artifact.
 *
 * @param {string} token The JWT for auth.
 * @param {string} scanId The scan Id the artifact is associated with.
 * @param {string} artifactKey The key for the artifact.
 * @returns The URL to get the artifact from.
 */
export const FetchScanArtifactURL = (token, scanId, artifactKey) => {
  return axios
    .request({
      url: `/scans/${scanId}/artifacts/${encodeURIComponent(artifactKey)}`,
      headers: { Authorization: `Bearer ${token}` },
      method: "get",
      maxRedirects: 0,
    })
    .then((res) => {
      // This request may fail due to how browsers handle redirects
      // (e.g. it will fail in Chrome but not Firefox or Safari).
      // For consistency, we will return the pre-signed response url regardless of success or error.
      return res?.request?.responseURL;
    });
  // There used to be code here that handled a case in Chrome where redirects
  // were followed differently than in Firefox and Safari. The workaround was to
  // return the pre-signed response url regardless of success or error in a catch
  // block.
  // Change was introduced in Chrome 119.0.6045.53/stable
  // Link to change: https://chromiumdash.appspot.com/commit/7ef125b6676441e404f0073c16eee37ba7e3b53f
};

/**
 * Gets the link to an artifact after failure to redirect.
 * Gets the artifact as an attachment.
 *
 * @param {string} token The JWT for auth.
 * @param {string} scanId The scan Id the artifact is associated with.
 * @param {string} artifactKey The key for the artifact.
 * @returns The URL to get the artifact from.
 */
export const FetchScanArtifactAttachment = (token, scanId, artifactKey) => {
  return axios
    .request({
      url: `/scans/${scanId}/artifacts/${encodeURIComponent(artifactKey)}`,
      headers: { Authorization: `Bearer ${token}` },
      method: "get",
      maxRedirects: 0,
    })
    .then((res) => {
      // This request may fail due to how browsers handle redirects
      // (e.g. it will fail in Chrome but not Firefox or Safari).
      // In the case of failing, we will save the pre-signed url for later use.
      if (res?.data) {
        return res.data;
      }
    })
    .catch((error) => {
      // The Solis API will return a 400 client error in the event the specified artifact cannot
      // be found.
      if (error.request.responseURL && !isSolisClientError(error)) {
        return error.request.responseURL;
      } else {
        logApiError(error);
        throw error;
      }
    });
};

/**
 * Fetches a scan artifact and displays it inline the document.
 *
 * @param {string} token The JWT used for authorization.
 * @param {string} scanId The object Id correlated to the scan document in the database.
 * @param {string} artifactKey The key to lookup the artifact at.
 * @returns Returns a presigned URL when the standard request inevitably fails.
 */
export const FetchScanArtifactInline = (token, scanId, artifactKey) => {
  return axios
    .request({
      url: `/scans/${scanId}/artifacts/${encodeURIComponent(artifactKey)}`,
      headers: { Authorization: `Bearer ${token}` },
      method: "get",
      maxRedirects: 0,
      params: {
        "response-content-disposition": "inline",
      },
      responseType: "arraybuffer",
    })
    .then((res) => {
      // This request may fail due to how browsers handle redirects.
      // (e.g. it will fail in Chrome but not Firefox or Safari).
      // In the case of failing, we will save the pre-signed url for later use. We will
      if (res?.data) {
        return res;
      }
    })
    .catch((error) => {
      // The Solis API will return a 400 client error in the event the specified artifact cannot
      // be found.
      if (error.request.responseURL && !isSolisClientError(error)) {
        return error.request.responseURL;
      } else {
        logApiError(error);
        throw error;
      }
    });
};

/**
 * Fetches a single result from a scan.
 *
 * @param {string} token The JWT for auth.
 * @param {string} scanId The Scan ID for the result.
 * @param {string} resultId The ID for the result.
 * @returns The result object data.
 */

// TODO :: need chat with David about catch conditional functionality

export const FetchScanResult = (token, scanId, resultId) => {
  return apiRequest(
    `/scans/${scanId}/result-instances/${resultId}`,
    "get",
    token,
    null,
    null,
    null
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      logApiError(error);
      return { status: "", details: [] };
    });
};

/**
 * Fetches results for a scanId.
 *
 * @param {string} token The JWT being worked with.
 * @param {string} scanId The scan Id to get results for.
 * @returns Results document for the scans.
 */
export const FetchScanResults = (
  token,
  scanId,
  pageSize = 150,
  page = 1,
  filter = null,
  sortBy = null,
  removeDetails = null
) => {
  let queryParams = { pageSize: pageSize, page: page };
  if (filter) {
    queryParams = {
      ...queryParams,
      filter: encodeURIComponent(JSON.stringify(filter)),
    };
  }
  if (sortBy) {
    queryParams = {
      ...queryParams,
      sortBy,
    };
  }
  if (removeDetails) {
    queryParams = {
      ...queryParams,
      removeDetails,
    };
  }
  return apiRequest(
    `/scans/${scanId}/results`,
    "get",
    token,
    queryParams,
    null,
    null
  ).then((res) => {
    return res.data;
  });
};

/**
 * Fetches results for an app and a target.
 *
 * @param {string} token The JWT for authorization.
 * @param {string} appId The Id for the app in question.
 * @param {string} targetId The Id for the target in question.
 * @returns The results document.
 */
export const FetchResults = (
  token,
  appId,
  targetId,
  pageSize = 150,
  page = 1,
  filter = null,
  sortBy = null,
  removeDetails = null
) => {
  let queryParams = { pageSize: pageSize, page: page };
  if (filter) {
    queryParams = {
      ...queryParams,
      filter: encodeURIComponent(JSON.stringify(filter)),
    };
  }
  if (sortBy) {
    queryParams = {
      ...queryParams,
      sortBy,
    };
  }
  if (removeDetails) {
    queryParams = {
      ...queryParams,
      removeDetails,
    };
  }
  return apiRequest(
    `/apps/${appId}/targets/${targetId}/results`,
    "get",
    token,
    queryParams,
    null,
    null
  ).then((res) => {
    return res.data;
  });
};

export const FetchAllResults = (
  token,
  pageSize = 50,
  page = 1,
  filter = null,
  sortBy = null,
  removeDetails = null
) => {
  let queryParams = { pageSize: pageSize, page: page };
  if (filter) {
    queryParams = {
      ...queryParams,
      filter: encodeURIComponent(JSON.stringify(filter)),
    };
  }
  if (sortBy) {
    queryParams = {
      ...queryParams,
      sortBy,
    };
  }
  if (removeDetails) {
    queryParams = {
      ...queryParams,
      removeDetails,
    };
  }
  return apiRequest(`/results`, "get", token, queryParams, null, null).then(
    (res) => {
      return res.data;
    }
  );
};

/**
 * Fetches the results based on a URL with the query params and filter.
 *
 * @param {string} token The JWT for authorization.
 * @param {string} url The URL for getting results.
 * @returns Results document from filter and query params.
 */
export const FetchResultsFromURL = (token, url) => {
  return apiRequest(url, "get", token)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      logApiError(error);
    });
};

/**
 * Fetches a result from app Id target Id and result ID.
 *
 * @param {string} token The JWT for auth.
 * @param {string} appId The app ID that this result is related to.
 * @param {string} targetId The target ID that this result is related to.
 * @param {string} resultId The result ID for this result.
 * @returns A result document.
 */
export const FetchResult = (token, appId, targetId, resultId) => {
  return apiRequest(
    `/apps/${appId}/targets/${targetId}/results/${resultId}`,
    "get",
    token,
    null,
    null,
    null
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      logApiError(error);
    });
};

/**
 * Fetches a PSB report from app Id and target Id
 *
 * @param {string} token The JWT for auth.
 * @param {string} appId The app Id that this report is related to.
 * @param {string} targetId The target ID that this report is related to.
 * @returns A report document.
 */
export const FetchPSBReport = (token, appId, targetId) => {
  return apiRequest(
    `/apps/${appId}/targets/${targetId}/reports/psb`,
    "get",
    token
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      logApiError(error);
    });
};

/**
 * Fetches a Dare report from app Id and target Id
 *
 * @param {string} token The JWT for auth.
 * @param {string} appId the app Id that this report is related to.
 * @param {string} targetId The target Id that this report is related to.
 * @returns A report document.
 */
export const FetchDareReport = (token, appId, targetId) => {
  return apiRequest(
    `/apps/${appId}/targets/${targetId}/reports/dare`,
    "get",
    token
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      logApiError(error);
    });
};

/**
 * Posts a Dare report to Security Insights
 *
 * @param {string} token The JWT for auth.
 * @param {string} appId the app Id that this report is related to.
 * @param {string} targetId the target Id that this report is related to.
 * @param {array} data the requirement ID's that are sent to DARE.
 * @returns A error or success status and CSDL information.
 */
export const PostDareReport = (token, appId, targetId, data) => {
  const payload = {
    req_ids: data,
  };
  return apiRequest(
    `/apps/${appId}/targets/${targetId}/reports/dare`,
    "post",
    token,
    null,
    payload,
    null
  ).then((resp) => {
    return resp;
  });
};

/**
 * Adds a new comment.
 *
 * @param {string} token The JWT for auth.
 * @param {string} appId The app ID that this result is related to.
 * @param {string} targetId The target ID that this result is related to.
 * @param {string} resultId The result ID for this result.
 * @param {string} cecId The user that is commenting.
 * @param {string} comment The comment text itself.
 * @param {*} revision The revision document for object history.
 * @returns A result document.
 */
export const AddComment = (
  token,
  appId,
  targetId,
  resultId,
  cecId,
  comment,
  revision
) => {
  let commentPayload = {
    comments: {
      action: "add",
      entries: [
        {
          created_by: cecId,
          message: comment,
        },
      ],
    },
    revision: revision,
  };
  return apiRequest(
    `/apps/${appId}/targets/${targetId}/results/${resultId}`,
    "patch",
    token,
    null,
    commentPayload,
    null
  ).then(() => {
    return FetchResult(token, appId, targetId, resultId);
  });
};

/**
 * Deletes an existing comment.
 *
 * @param {string} token The JWT for auth.
 * @param {string} appId The app ID that this result is related to.
 * @param {string} targetId The target ID that this result is related to.
 * @param {string} resultId The result ID for this result.
 * @param {string} commentId The comment text itself.
 * @param {*} revision The revision object of the result.
 * @returns A result document.
 */
export const DeleteComment = (
  token,
  appId,
  targetId,
  resultId,
  commentId,
  revision
) => {
  let commentPayload = {
    comments: {
      action: "remove",
      entries: [
        {
          _id: commentId,
        },
      ],
    },
    revision: revision,
  };
  return apiRequest(
    `/apps/${appId}/targets/${targetId}/results/${resultId}`,
    "patch",
    token,
    null,
    commentPayload,
    null
  ).then(() => {
    return FetchResult(token, appId, targetId, resultId);
  });
};

/**
 * Updates an existing comment.
 *
 * @param {string} token The JWT for auth.
 * @param {string} appId The app ID that this result is related to.
 * @param {string} targetId The target ID that this result is related to.
 * @param {string} resultId The result ID for this result.
 * @param {string} commentId The comment text itself.
 * @param {string} message The message for the updated comment.
 * @param {string} createdBy The user that created the comment.
 * @param {*} revision The revision document for the result.
 * @returns A result document.
 */
export const UpdateComment = (
  token,
  appId,
  targetId,
  resultId,
  commentId,
  message,
  createdBy,
  revision
) => {
  let commentPayload = {
    comments: {
      action: "replace",
      entries: [
        {
          _id: commentId,
          message: message,
          created_by: createdBy,
        },
      ],
    },
    revision: revision,
  };
  return apiRequest(
    `/apps/${appId}/targets/${targetId}/results/${resultId}`,
    "patch",
    token,
    null,
    commentPayload,
    null
  ).then(() => {
    return FetchResult(token, appId, targetId, resultId);
  });
};

/**
 * Updates triage values that can be edited in the form.
 *
 * @param {string} token The JWT used for auth.
 * @param {string} appId The application ID for this result.
 * @param {string} targetId The target ID for this result.
 * @param {string} resultId The result ID having its triage data updated.
 * @param {object} triage A triage object.
 * @returns A result document.
 */
export const UpdateTriage = (token, appId, targetId, resultId, triage) => {
  return apiRequest(
    `/apps/${appId}/targets/${targetId}/results/${resultId}`,
    "patch",
    token,
    null,
    triage,
    null
  ).then(() => {
    return FetchResult(token, appId, targetId, resultId);
  });
};

/**
 * Fetches stats for the given date.
 *
 * @param {string} token The JWT token to send.
 * @param {string} date The date to fetch stats for. In the format "year-month-day".
 * @returns The response of the axios request.
 */
export const FetchDailyStats = (token, date) => {
  return apiRequest(
    `/stats/platform/${date}`,
    "get",
    token,
    null,
    null,
    null
  ).then((resp) => {
    const data = resp.data;
    return data;
  });
};

/**
 * Fetches a page of stats.
 *
 * @param {string} token The JWT token to send.
 * @param {number} pageSize The size of the page to request.
 * @param {number} page The page number.
 * @returns The response of the axios request.
 */
export const FetchStats = (token, pageSize = 25, page = 1) => {
  return apiRequest(
    "/stats/platform",
    "get",
    token,
    { pageSize: pageSize, page: page },
    null,
    null
  )
    .then((resp) => {
      const statsDocs = resp.data.stats;
      return statsDocs;
    })
    .catch((error) => {
      logApiError(error);
      return [];
    });
};

/**
 * Fetches personal stats for a user from the /stats/personal
 * endpoint.
 *
 * @param {string} token The JWT authorization token.
 * @param {string} appId The appId that the stats should be gathered on
 * @param {string} targetId The targetId that the stats should be gathered on
 * @param {string} estimate Whether to estimate the counts or not.
 * @returns The response of the axios request.
 */
export const FetchPersonalStats = async (
  token,
  appId = null,
  targetId = null,
  estimate = true
) => {
  let params = {
    estimate,
  };
  if (appId) {
    params = { ...params, appId };
  }
  if (targetId) {
    params = { ...params, targetId };
  }

  const resp = await apiRequest(
    "/stats/personal",
    "get",
    token,
    params,
    null,
    null
  );
  return resp.data;
};

/**
 * Fetches app users and their app role.
 *
 * @param {string} token The JWT token to send.
 * @param {string} app_id The application id.
 * @returns The response of the axios request.
 */
export const FetchAppUsers = (token, appId, pageSize = 25, page = 1) => {
  return apiRequest(
    `/apps/${appId}/users`,
    "get",
    token,
    { pageSize: pageSize, page: page },
    null,
    null
  ).then((resp) => {
    const users = resp.data;
    return users;
  });
};

/**
 * Adds a new User to an application.
 *
 * @param {string} token The JWT for authorization.
 * @param {string} cecId The Id of the new user.
 * @param {string} appId The Id of the application the user will be added to.
 * @param {string} role The role of the new user (admin, auditor, or user).
 * @returns The response.
 */
export const AddAppUser = async (token, cecId, appId, role) => {
  const payload = {
    app_id: appId,
    cec_id: cecId,
    role: role,
  };
  return apiRequest(
    "/users/app-roles",
    "post",
    token,
    null,
    payload,
    null
  ).then((res) => {
    return res;
  });
};

/**
 * Removes a user from an application.
 *
 * @param {string} token The JWT for authorization.
 * @param {string} cecId The Id of the new user.
 * @param {string} appId The Id of the application the user will be added to.
 * @param {string} role The role of the new user (admin, auditor, or user).
 * @returns The response.
 */
export const RemoveAppUser = (token, cecId, appId) => {
  const payload = {
    app_id: appId,
    cec_id: cecId,
    role: "null",
  };
  return apiRequest(
    "/users/app-roles",
    "post",
    token,
    null,
    payload,
    null
  ).then((res) => {
    return res;
  });
};

/**
 * Edits a user's role within an application.
 *
 * @param {string} token The JWT for authorization.
 * @param {string} cecId The Id of the new user.
 * @param {string} appId The Id of the application the user will be added to.
 * @param {string} role The role of the new user (admin, auditor, or user).
 * @returns The response.
 */
export const EditAppUser = async (token, cecId, appId, role) => {
  const payload = {
    app_id: appId,
    cec_id: cecId,
    role: role,
  };
  return apiRequest(
    "/users/app-roles",
    "post",
    token,
    null,
    payload,
    null
  ).then((res) => {
    return res;
  });
};

/**
 * Gets secrets for a given app.
 *
 * @param {string} token A JWT authorization token.
 * @param {string} appId The Id for the app.
 * @returns The response from the request.
 */
export const FetchSecretsForApp = async (token, appId) => {
  const response = await apiRequest(
    `/apps/${appId}/config-secrets`,
    "get",
    token
  );
  return response;
};

/**
 * Saves a new secret for the app.
 *
 * @param {string} token A JWT authorization token.
 * @param {string} appId The Id for the app.
 * @param {*} payload The payload to create the secret.
 * @returns The response from the request.
 */
export const SaveSecretForApp = async (token, appId, payload) => {
  const response = await apiRequest(
    `/apps/${appId}/config-secrets`,
    "post",
    token,
    null,
    payload
  );
  return response;
};

/**
 * Deletes a secret of the app by the given name.
 *
 * @param {string} token JWT authorization token.
 * @param {string} appId The ID of the app that this secret is owned by.
 * @param {string} secretId The ID of the secret to delete.
 * @returns The response from the request.
 */
export const DeleteSecretForApp = async (token, appId, secretId) => {
  return await apiRequest(
    `/apps/${appId}/config-secrets/${secretId}`,
    "delete",
    token
  );
};

/**
 * Updates a secret with a new value.
 *
 * @param {string} token JWT authorization token.
 * @param {string} appId The ID of the app that this secret is owned by.
 * @param {string} secretId The ID of the secret to delete.
 * @param {*} payload The update to make to the secret.
 * @returns The response from the request.
 */
export const UpdateSecretForApp = async (token, appId, secretId, payload) => {
  return await apiRequest(
    `/apps/${appId}/config-secrets/${secretId}`,
    "put",
    token,
    null,
    payload
  );
};

/**
 * Fetches all of the available chariots to the user.
 *
 * @param {string} token The JWT used for authorization.
 * @param {number} pageSize The number of chariots to include in a single response.
 * @param {number} page The page number of chariots to return.
 * @param {*} filter Any filters on the results.
 * @returns The axios request Promise.
 */
export const FetchChariots = (
  token,
  pageSize = 25,
  page = 1,
  filter = null
) => {
  let queryParams = { pageSize: pageSize, page: page };
  if (filter) {
    queryParams = {
      ...queryParams,
      filter: encodeURIComponent(JSON.stringify(filter)),
    };
  }
  return apiRequest("/chariots", "get", token, queryParams, null, null).then(
    (resp) => {
      return resp.data;
    }
  );
};

/**
 * Fetches a single chariot by ID.
 *
 * @param {string} token The JWT used for authorization.
 * @param {string} chariotId The chariot Id to fetch.
 * @returns The axios request Promise.
 */
export const FetchChariot = (token, chariotId) => {
  return apiRequest(`/chariots/${chariotId}`, "get", token).then((resp) => {
    return resp.data;
  });
};

/**
 * Request for a presigned URL to upload a file to.
 *
 * @param {string} token Authorization JWT
 * @param {string} appId The App ID for scope of this request.
 * @param {string} filename The file we are downloading.
 * @param {string} description A description for the file being uploaded.
 * @returns The URL and keys to send to the presigned URL.
 */
export const UploadFilePresignedUrl = (token, appId, filename, description) => {
  const body = {
    filename: filename,
    description: description,
  };
  return apiRequest(
    `/apps/${appId}/files`,
    "post",
    token,
    null,
    body,
    null
  ).then((res) => {
    if (res.status === 201) {
      return res.data;
    }
  });
};

/**
 * Request to delete an external file stored in scope of this app.
 *
 * @param {string} token Authorization JWT
 * @param {string} appId The App ID for scope of this request.
 * @param {string} filename The file we are downloading.
 * @returns Whether the deletion was successful.
 */
export const DeleteFile = (token, appId, filename) => {
  return apiRequest(
    `/apps/${appId}/files/${filename}`,
    "delete",
    token,
    null,
    null,
    null
  ).then((res) => {
    if (res.status === 204) {
      return true;
    }
  });
};

/**
 * Request to download an external file stored in scope for this app.
 *
 * @param {string} token Authorization JWT
 * @param {string} appId The App ID for scope of this request.
 * @param {string} filename The file we are downloading.
 * @returns Promise that resolves with an error containing the download URL.
 */
export const FetchExternalFile = (token, appId, filename) => {
  return axios
    .request({
      url: `/apps/${appId}/files/${encodeURIComponent(filename)}`,
      headers: { Authorization: `Bearer ${token}` },
      method: "get",
      maxRedirects: 0,
    })
    .then((response) => {
      return response?.request?.responseURL;
    });
  // There used to be code here that handled a case in Chrome where redirects
  // were followed differently than in Firefox and Safari. The workaround was to
  // return the pre-signed response url regardless of success or error in a catch
  // block.
  // Change was introduced in Chrome 119.0.6045.53/stable
  // Link to change: https://chromiumdash.appspot.com/commit/7ef125b6676441e404f0073c16eee37ba7e3b53f
};

/**
 * Fetches the AccessList and all the rules.
 *
 * @param {string} token Authorization JWT
 * @param {number} pageSize Amount of rules per page.
 * @param {number} page Current page that is retrieved.
 * @param {*} filter
 * @returns the response data from the request.
 */
export const FetchAccessLists = async (
  token,
  pageSize,
  page,
  ruleType = null
) => {
  let queryParams = { pageSize: pageSize, page: page, ruleType: ruleType };
  const response = await apiRequest(
    `/access-list`,
    "GET",
    token,
    queryParams,
    null,
    null
  );
  return response.data;
};

/**
 * Adds a rule to the AccessList.
 *
 * @param {string} token JWT Authorization
 * @param {object} payload AccessList rule to be added.
 * @returns the response data from the request.
 */
export const AddAccessLists = async (token, payload) => {
  const resp = await apiRequest(`/access-list`, "POST", token, null, payload);
  return resp.data;
};

/**
 * Deletes a rule from the access list.
 *
 * @param {string} token JWT Authorization
 * @param {string} accessListId Accesslist rule ID to be deleted.
 * @returns the response from the request.
 */
export const DeleteAccessLists = async (token, accessListId) => {
  const resp = await apiRequest(
    `/access-list/${accessListId}`,
    "DELETE",
    token
  );
  return resp;
};

/**
 * Edits a rule from the access list.
 *
 * @param {string} token JWT Authorization
 * @param {string} accessListId Accesslist rule ID to be edited.
 * @param {object} payload Updated Accesslist rule.
 * @returns response from the request.
 */
export const PatchAccessLists = async (token, accessListId, payload) => {
  const resp = await apiRequest(
    `/access-list/${accessListId}`,
    "PATCH",
    token,
    null,
    payload
  );
  return resp;
};

/**
 * Checks a url for whether it would be allowed or not.
 *
 * @param {string} token JWT Authorization.
 * @param {string} url URL that is being checked against the accesslist.
 * @returns response data from the request.
 */
export const CheckAccessList = async (token, url) => {
  const resp = await apiRequest(
    `/access-list/check-url`,
    "POST",
    token,
    null,
    JSON.stringify({ url: url })
  );
  return resp.data;
};

/**
 * Sends a request to refresh the token using the custom
 * axios instance for this task.
 *
 * @returns Promise to resolve the request
 */
export const RefreshToken = () => {
  return refreshTokenAxiosInstance.get("/refresh-token", {
    signal: abortController.signal,
    withCredentials: true,
    skipAuthRefresh: true,
  });
};

/**
 * Adds a new notification doc to an app target
 *
 * @param {string} token JWT Authorization.
 * @param {string} owner_app_id The ID of the app that owns the target.
 * @param {string} target_id The ID of the target will own the notification.
 * @param {string} destination_id The ID of the destination document to send things to.
 * @param {string} name The value of the name field in the notification document.
 * @param {*} trigger_event The trigger event that dictates when the notification should trigger.
 * @param {string} description The value of the description field in the notification document.
 * @param {boolean} enabled The value of the enabled field in the notification document.
 *
 * @returns response data from the request.
 */
export const AddNotification = async (
  token,
  owner_app_id,
  target_id,
  destination_id,
  name,
  trigger_event,
  description = null,
  enabled = true
) => {
  let payload = {
    owner_app_id,
    target_id,
    destination_id,
    name,
    enabled,
    trigger_event,
  };
  if (description) payload["description"] = description;
  return apiRequest("/notifications", "POST", token, null, payload);
};

/**
 * Reads notification docs from the API
 *
 * @param {string} token JWT Authorization.
 * @param {number} page The page of notifications to get.
 * @param {number} pageSize The maximum number of notifications to get back.
 * @param {*} filter The filter query to send to the API.
 * @param {boolean} includeEmbedded Whether embedded documents should be added to the documents.
 *
 * @returns response data from the request.
 */
export const GetNotifications = async (
  token,
  page,
  pageSize,
  filter = null,
  includeEmbedded = false
) => {
  let queryParams = {
    page,
    pageSize,
  };
  if (filter) {
    queryParams = { ...queryParams, filter };
  }
  if (includeEmbedded) {
    queryParams = { ...queryParams, includeEmbedded };
  }
  const resp = await apiRequest("/notifications", "GET", token, queryParams);
  return resp.data;
};

/**
 * Updates notification doc with the API
 *
 * @param {string} token JWT Authorization.
 * @param {string} id The ID of the notification document to update.
 * @param {*} payload The body with which to update the notification.
 * @param {*} revision The current revision of the notification document to update from.
 *
 * @returns response data from the request.
 */
export const UpdateNotification = async (token, id, payload, revision) => {
  const editPayload = {
    ...payload,
    revision,
  };
  return apiRequest(`/notifications/${id}`, "PATCH", token, null, editPayload);
};

/**
 * Deletes notification doc with the API
 *
 * @param {string} token JWT Authorization.
 * @param {string} id The ID of the notification document to delete.
 *
 * @returns response data from the request.
 */
export const DeleteNotification = (token, id) => {
  return apiRequest(`/notifications/${id}`, "DELETE", token, null, null);
};

/**
 * Adds a new notification destination doc to an app
 *
 * @param {string} token JWT Authorization.
 * @param {string} owner_app_id The ID of the app that will own the destination.
 * @param {string} name The value of the name field in the destination document.
 * @param {string} type The type of notification destination.
 * @param {*} config The config data used for sending the notification to the destination.
 *
 * @returns response data from the request.
 */
export const AddNotificationDestination = async (
  token,
  owner_app_id,
  name,
  type,
  config
) => {
  const payload = {
    owner_app_id,
    name,
    type,
    config,
  };
  return apiRequest("/notification-destinations", "POST", token, null, payload);
};

/**
 * Reads notification destination doc from the API
 *
 * @param {string} token JWT Authorization.
 * @param {string} id The ID of the notification destination to get.
 * @param {boolean} includeEmbedded Whether embedded documents should be added to the documents.
 *
 * @returns response data from the request.
 */
export const GetNotificationDestination = async (
  token,
  id,
  includeEmbedded = false
) => {
  if (!id) return;
  let queryParams = {};
  if (includeEmbedded) {
    queryParams = { ...queryParams, includeEmbedded };
  }
  const resp = await apiRequest(
    `/notification-destinations/${id}`,
    "GET",
    token,
    queryParams
  );
  return resp.data;
};

/**
 * Reads notification destination docs from the API
 *
 * @param {string} token JWT Authorization.
 * @param {number} page The page of notification destinations to get.
 * @param {number} pageSize The maximum number of notification destinations to get back.
 * @param {*} filter The filter query to send to the API.
 * @param {boolean} includeEmbedded Whether embedded documents should be added to the documents.
 *
 * @returns response data from the request.
 */
export const GetNotificationDestinations = async (
  token,
  page,
  pageSize,
  filter = null,
  includeEmbedded = false
) => {
  let queryParams = {
    page,
    pageSize,
  };
  if (filter) {
    queryParams = { ...queryParams, filter };
  }
  if (includeEmbedded) {
    queryParams = { ...queryParams, includeEmbedded };
  }
  const resp = await apiRequest(
    "/notification-destinations",
    "GET",
    token,
    queryParams
  );
  return resp.data;
};

/**
 * Updates notification destination doc with the API
 *
 * @param {string} token JWT Authorization.
 * @param {string} id The ID of the notification destination document to update.
 * @param {*} payload The body with which to update the notification destination.
 * @param {*} revision The current revision of the destination document to update from.
 *
 * @returns response data from the request.
 */
export const UpdateNotificationDestination = (token, id, payload, revision) => {
  const editPayload = {
    ...payload,
    revision,
  };
  return apiRequest(
    `/notification-destinations/${id}`,
    "PATCH",
    token,
    null,
    editPayload
  );
};

/**
 * Deletes notification destination doc with the API
 *
 * @param {string} token JWT Authorization.
 * @param {string} id The ID of the notification destination document to delete.
 *
 * @returns response data from the request.
 */
export const DeleteNotificationDestinations = (token, id) => {
  return apiRequest(
    `/notification-destinations/${id}`,
    "DELETE",
    token,
    null,
    null
  );
};

/**
 * Reads external ticket docs from the API
 *
 * @param {string} token JWT Authorization.
 * @param {string} appId The ID of app that owns the tickets.
 * @param {string} targetId The ID of the target that owns the tickets.
 * @param {number} page The page of documents to get.
 * @param {number} pageSize The maximum number of documents to get back.
 * @param {*} filter The filter query to send to the API.
 *
 * @returns response data from the request.
 */
export const FetchExternalTickets = async (
  token,
  appId,
  targetId,
  page,
  pageSize = defaultPageSize,
  filter = null
) => {
  const queryParams = {
    page,
    pageSize,
  };
  if (filter) {
    queryParams.filter = filter;
  }
  const response = await apiRequest(
    `/apps/${appId}/targets/${targetId}/external-tickets`,
    "GET",
    token,
    queryParams
  );
  return response.data;
};

/**
 * Triggers the syncing process of tickets owned by the target.
 *
 * @param {string} token JWT Authorization.
 * @param {string} appId The ID of app that owns the tickets.
 * @param {string} targetId The ID of the target that owns the tickets.
 *
 * @returns response data from the request.
 */
export const SyncTargetExternalTickets = async (token, appId, targetId) => {
  return apiRequest(
    `/apps/${appId}/targets/${targetId}/external-tickets`,
    "PATCH",
    token,
    null,
    {}
  );
};

/**
 * Reads a external ticket doc from the API
 *
 * @param {string} token JWT Authorization.
 * @param {string} appId The ID of app that owns the tickets.
 * @param {string} targetId The ID of the target that owns the tickets.
 * @param {string} ticketId The ID of the ticket to fetch.
 *
 * @returns response data from the request.
 */
export const FetchExternalTicket = async (token, appId, targetId, ticketId) => {
  const response = await apiRequest(
    `/apps/${appId}/targets/${targetId}/external-tickets/${ticketId}`,
    "GET",
    token
  );
  return response.data;
};

/**
 * Reads ticketing rule docs from the API
 *
 * @param {string} token JWT Authorization.
 * @param {number} page The page of documents to get.
 * @param {number} pageSize The maximum number of documents to get back.
 * @param {*} filter The filter query to send to the API.
 *
 * @returns response data from the request.
 */
export const FetchTicketingRules = async (
  token,
  page,
  pageSize = defaultPageSize,
  filter = null
) => {
  const queryParams = {
    page,
    pageSize,
  };
  if (filter) {
    queryParams.filter = filter;
  }
  const response = await apiRequest(
    "/ticketing-rules",
    "GET",
    token,
    queryParams
  );
  return response.data;
};

/**
 * Reads a ticketing rule doc from the API
 *
 * @param {string} token JWT Authorization.
 * @param {string} ticketingRuleId The document id of the ticketing rule to fetch.
 *
 * @returns response data from the request.
 */
export const FetchTicketingRule = async (token, ticketingRuleId) => {
  const response = await apiRequest(
    `/ticketing-rules/${ticketingRuleId}`,
    "GET",
    token
  );
  return response.data;
};

/**
 * Adds a ticketing rule doc with the API
 *
 * @param {string} token JWT Authorization.
 * @param {string} newRule The document data of the ticketing rule to create.
 *
 * @returns response data from the request.
 */
export const AddTicketingRule = async (token, newRule) => {
  const response = await apiRequest(
    "/ticketing-rules",
    "POST",
    token,
    null,
    newRule
  );
  return response.data;
};

/**
 * Updates ticketing rule doc with the API
 *
 * @param {string} token JWT Authorization.
 * @param {string} id The ID of the ticketing rule document to update.
 * @param {*} payload The body with which to update the ticketing rule.
 * @param {*} revision The current revision of the ticketing rule document to update from.
 *
 * @returns response data from the request.
 */
export const UpdateTicketingRule = (token, id, payload, revision) => {
  const editPayload = {
    ...payload,
    revision,
  };
  return apiRequest(
    `/ticketing-rules/${id}`,
    "PATCH",
    token,
    null,
    editPayload
  );
};

/**
 * Deletes ticketing rule doc with the API
 *
 * @param {string} token JWT Authorization.
 * @param {string} id The ID of the ticketing rule document to delete.
 *
 * @returns response data from the request.
 */
export const DeleteTicketingRule = (token, id) => {
  return apiRequest(`/ticketing-rules/${id}`, "DELETE", token, null, null);
};

/**
 * Adds a personal access token with the API
 *
 * @param {string} token JWT Authorization.
 * @param {object} payload The payload for creating a personal access token.
 * @param {string} userId The id of the user that the access token is getting created for.
 *
 * @returns response data from the request.
 */
export const AddPersonalAccessToken = async (token, payload, userId) => {
  const tokenPayload = {
    ...payload,
  };
  return apiRequest(
    `/users/${userId}/tokens`,
    "POST",
    token,
    null,
    tokenPayload,
    null
  ).then((res) => {
    return res;
  });
};

/**
 * Deletes a personal access token with the API.
 *
 * @param {string} token JWT Authorization.
 * @param {string} id The id of the personal access token to delete.
 * @param {string} userId The id of the user that the access token is for.
 *
 * @returns response data from the request.
 */
export const DeletePersonalAccessToken = async (token, id, userId) => {
  return apiRequest(
    `/users/${userId}/tokens/${id}`,
    "DELETE",
    token,
    null,
    null
  );
};
